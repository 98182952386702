<style scoped>
    .contact-detail-container {
        display: flex;
        align-items: center;
    }

    :deep(.list-head) {
        padding-right: 30px;
    }

    :deep(.list-head ul li:first-child) {
        width: 5%;
    }

    :deep(.list-head ul li:nth-child(2)), :deep(.list-head ul li:nth-child(3)), :deep(.list-head ul li:nth-child(4)), :deep(.list-head ul li:nth-child(5)), :deep(.list-head ul li:nth-child(6)) {
        width: 15.75%;
    }

    .modal-menu-title {
        text-align: center;
        color: rgb(50, 60, 70);
        font-size: 13px;
        margin: 30px 0px 30px 0px;
    }

    :deep(.fieldset-container) {
        margin: 10px 0px 15px 0px !important;
    }

    .contact-button-container {
        margin-top: 15px;
        margin-left: 25px;
        justify-content: center;
    }

    :deep(.inputeo) {
        min-width: unset;
    }

    .note-container, .contact-container {
        width: 96%;
        margin: 25px auto 0 auto;
        padding-bottom: 25px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
        grid-auto-rows: minmax(100px, auto);
    }
    .contact-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        max-height: 400px;
        overflow-y: auto;
        border-radius: 10px;
        border: 1px solid #F1F1F1;
    }

    .card-item {
        padding: 5px;
    }

    .card-item .inputeo {
        margin: 0 5px 10px 5px;
        width: 100%;
    }

    .card-item .note-item_header {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        z-index: 1000;
    }

    .card-item .note-item_time {
        margin: 5px;
    }

    .card-item .note-item_contacts {
        height: auto;
        width: calc(100% - 50px);
        margin-bottom: 10px;
    }

    .contact-tag {
        display: inline-block;
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        color: white;
        padding: 3px 10px;
        border-radius: 4px;
        margin: 5px;
    }

    .add-note-button-div {
        grid-column: span 1;
        grid-row: span 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    .add-note-button-div .inputeo {
        margin: 0 0 10px 0;
        width: 100%;
    }

    .add-note-button-div .add-note-title {
        color: #505A64;
        margin: 5px 0 15px 8px;
        font-size: 18px;
    }

    .add-note-button {
        width: 100%;
        margin: 0 !important;
        color: #646e78 !important;
        font-size: 25px !important;
    }

    .add-note-button svg {
        margin: auto;
    }

    .delete-note-button {
        display: flex;
        float: right;
        width: 30px;
        background-color: #FFF !important;
        color: #646e78 !important;
        border: 1px solid #646e78 !important;
    }

    .exit-btn {
        display: inline-block;
        position: absolute;
        right: 0;
    }
</style>

<template>
    <ButtonTopbar buttonText="Créer un fournisseur" @onTopbarButtonClicked="displayCreateSupplierModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        apiRoute='supplier/list'
        :apiParams="filter"
        :columnDefs="columnDefs" 
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="displayEditSupplierModal"
    >
        <template v-slot:filter>
            <BaseCheckbox label="Afficher les fournisseurs supprimés" @onChange="onDeletedFilterChange"/>
            <div>
                <BaseInput v-model="search" label="Rechercher un fournisseur" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayModalCreateOrEditSupplier === true" :fullscreen="true" mode="menu">
        <template v-slot:modalMenu>
            <ModalMenu ref="supplierModalMenu" :menuItems="menuItems" :startTab="startSupplierModalTab" @onExit="closeModal" v-on:update:startTab="startSupplierModalTab = $event">
                <template v-slot:modalMenuHead>
                    <div class="modal-menu-title">
                        <span v-show="createOrEditMode === 'create'">Créer un fournisseur</span>
                        <span v-show="createOrEditMode === 'edit'">Modifier un fournisseur</span>
                    </div>
                </template>

                <template v-slot:modalTabs>                    
                    <div data-tab-name="general">
                        <form>
                            <div>
                                <BaseInput 
                                    v-model="supplier.accountNumber" 
                                    label="Numéro de compte" 
                                    type="text" 
                                    name="accountNumber" 
                                    validator="supplierAccountNumber"
                                    :error="formErrors.step1?.accountNumber?.error?.message" 
                                    @onChange="onFormInputChange($event, 1)" 
                                    :displayError="displayError"
                                    :required="false" 
                                />
                                <BaseInput 
                                    v-model="supplier.name" 
                                    label="Nom" 
                                    type="text" 
                                    name="name" 
                                    validator="text" 
                                    :error="formErrors.step1?.name?.error?.message" 
                                    @onChange="onFormInputChange($event, 1)" 
                                    :displayError="displayError" 
                                />
                            </div>
                            <div>
                                <BaseInput 
                                    v-model="supplier.callKey" 
                                    label="Clé d'appel" 
                                    type="text" 
                                    name="callKey" 
                                    validator="text" 
                                    :error="formErrors.step1?.callKey?.error?.message" 
                                    @onChange="onFormInputChange($event, 1)" 
                                    :displayError="displayError"
                                    :disabled="createOrEditMode === 'edit'"
                                />
                                <BaseInput 
                                    v-model="supplier.siret" 
                                    label="SIRET" 
                                    type="text" 
                                    name="siret" 
                                    validator="text" 
                                    :error="formErrors.step1?.siret?.error?.message" 
                                    @onChange="onFormInputChange($event, 1)" 
                                    :displayError="displayError"
                                    :required="false"
                                />
                            </div>
                            <div>
                                <BaseInput 
                                    v-model="supplier.tvaNumber" 
                                    label="Numéro TVA" 
                                    type="text" 
                                    name="tvaNumber" 
                                    validator="text" 
                                    :error="formErrors.step1?.tvaNumber?.error?.message" 
                                    @onChange="onFormInputChange($event, 1)" 
                                    :displayError="displayError" 
                                    :required="false"
                                />
                                <BaseInput 
                                    v-model="supplier.nafCode" 
                                    label="Code NAF" 
                                    type="text" 
                                    name="nafCode" 
                                    validator="text" 
                                    :error="formErrors.step1?.nafCode?.error?.message" 
                                    @onChange="onFormInputChange($event, 1)" 
                                    :displayError="displayError" 
                                    :required="false"
                                />
                            </div>
                            <div>
                                <BaseInput 
                                    v-model="supplier.phoneNumber" 
                                    label="Téléphone" 
                                    type="text" 
                                    name="phoneNumber" 
                                    validator="phone" 
                                    :error="formErrors.step1?.phoneNumber?.error?.message" 
                                    @onChange="onFormInputChange($event, 1)" 
                                    :displayError="displayError" 
                                    :required="false"
                                />
                                <BaseInput 
                                    v-model="supplier.website" 
                                    label="Site internet" 
                                    type="text" 
                                    name="website" 
                                    validator="website" 
                                    :error="formErrors.step1?.website?.error?.message" 
                                    @onChange="onFormInputChange($event, 1)" 
                                    :displayError="displayError" 
                                    :required="false"
                                    @onButtonActionClick="onAccessWebsite(supplier.website)"
                                >
                                    <template v-slot:iconAction>
                                        <font-awesome-icon :icon="['fas', 'link']" />
                                    </template>
                                </BaseInput>
                            </div>
                            <FieldSet
                                label="Adresse de livraison & facturation"
                                :column="true"
                            >
                                <template v-slot:content>
                                    <div>
                                        <BaseInput
                                            v-model="supplier.deliveryAddressName"
                                            label="Nom"
                                            type="text"
                                            name="deliveryAddressName"
                                            validator="text"
                                            :required="false"
                                        />
                                    </div>
                                    <div>
                                        <BaseInput 
                                            v-model="supplier.deliveryAddress1" 
                                            label="Adresse 1" 
                                            type="text" 
                                            name="deliveryAddress1" 
                                            validator="text" 
                                            :error="formErrors.step1?.deliveryAddress1?.error?.message" 
                                            @onChange="onFormInputChange($event, 1)" 
                                            :displayError="displayError"
                                        />
                                        <BaseInput 
                                            v-model="supplier.deliveryAddress2" 
                                            label="Adresse 2" 
                                            type="text" 
                                        />
                                        <BaseInput 
                                            v-model="supplier.deliveryAddress3" 
                                            label="Adresse 3" 
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <BaseSelect
                                            v-model="supplier.deliveryZipCode" 
                                            label="Code postal"
                                            name="deliveryZipCode"
                                            api="commune/search"
                                            :apiParams="{
                                                column: 'zipCode'
                                            }"
                                            fieldValue="id"
                                            fieldLabel="zipCode"
                                            :searchable="true"
                                            :multiple="false"
                                            :required="true"
                                            track-by=""
                                            :min-chars="2"
                                            filter-duplicate="name"
                                            :error="formErrors.step1?.deliveryZipCode?.error?.message" 
                                            @onChange="onFormInputChange($event, 1)"
                                            :displayError="displayError"
                                            autoCompleteLabel="name"
                                            @autoComplete="e => supplier.deliveryTown = e"
                                            :allowCustomEntry="true"
                                        />
                                        <BaseSelect
                                            v-model="supplier.deliveryTown" 
                                            label="Ville"
                                            name="deliveryTown"
                                            api="commune/search"
                                            :apiParams="{
                                                column: 'name'
                                            }"
                                            fieldValue="id"
                                            fieldLabel="name"
                                            :searchable="true"
                                            :required="true"
                                            track-by=""
                                            :min-chars="2"
                                            :error="formErrors.step1?.deliveryTown?.error?.message" 
                                            @onChange="onFormInputChange($event, 1)" 
                                            :displayError="displayError"
                                            autoCompleteLabel="zipCode"
                                            @autoComplete="e => supplier.deliveryZipCode = e"
                                            :allowCustomEntry="true"
                                        />
                                        <BaseInput 
                                            v-model="supplier.deliveryCountry" 
                                            label="Pays" 
                                            type="text" 
                                            name="deliveryCountry" 
                                            validator="text" 
                                            :error="formErrors.step1?.deliveryCountry?.error?.message" 
                                            @onChange="onFormInputChange($event, 1)" 
                                            :displayError="displayError"
                                        />
                                    </div>
                                    
                                </template>
                            </FieldSet>
                        </form>
                    </div>
                    
                    <div data-tab-name="payment">
                        <form>
                            <div>
                                <BaseSelect 
                                    v-model="supplier.taxCode" 
                                    label="Code taxe" 
                                    name="taxCode"
                                    :error="formErrors.step2?.taxCode?.error?.message" 
                                    :defaultOptions="taxCode"
                                    @onChange="onFormInputChange($event, 2)" 
                                    :displayError="displayError" 
                                    :required="true"
                                />
                                <BaseSelect 
                                    v-model="supplier.taxSystem" 
                                    label="Régime fiscal"
                                    name="taxSystem"
                                    :error="formErrors.step2?.taxSystem?.error?.message" 
                                    :defaultOptions="taxSystem"
                                    @onChange="onFormInputChange($event, 2)" 
                                    :displayError="displayError" 
                                    :required="true"
                                />
                                <BaseSelect 
                                    v-model="supplier.currencie" 
                                    label="Devise"
                                    name="currencie"
                                    :error="formErrors.step2?.currencie?.error?.message" 
                                    :defaultOptions="currencie"
                                    @onChange="onFormInputChange($event, 2)" 
                                    :displayError="displayError" 
                                    :required="true"
                                />
                            </div>
                            <div>
                                <BaseSelect 
                                    v-model="supplier.language" 
                                    label="Langage" 
                                    name="language"
                                    :error="formErrors.step2?.language?.error?.message" 
                                    :defaultOptions="language"
                                    @onChange="onFormInputChange($event, 2)" 
                                    :displayError="displayError" 
                                    :required="true"
                                />
                                <BaseSelect 
                                    v-model="supplier.payment" 
                                    label="Réglement" 
                                    name="payment"
                                    :error="formErrors.step2?.payment?.error?.message" 
                                    :defaultOptions="payment"
                                    @onChange="onFormInputChange($event, 2)" 
                                    :displayError="displayError" 
                                    :required="true"
                                    :maxHeight="300"
                                />
                            </div>
                            <div>
                                <BaseSelect 
                                    v-model="supplier.deadline" 
                                    label="Base échéance" 
                                    name="deadline"
                                    :error="formErrors.step2?.deadline?.error?.message" 
                                    :defaultOptions="deadline"
                                    @onChange="onFormInputChange($event, 2)" 
                                    :displayError="displayError" 
                                    :required="true"
                                    :maxHeight="300"
                                />
                                <BaseInput 
                                    v-if="displayDueDay"
                                    v-model="supplier.dueDay" 
                                    label="Jour échéance" 
                                    type="text" 
                                    name="dueDay" 
                                    validator="number" 
                                    :error="formErrors.step2?.dueDay?.error?.message" 
                                    @onChange="onFormInputChange($event, 2)" 
                                    :displayError="displayError"
                                />
                            </div>
                        </form>
                    </div>

                    <div data-tab-name="contact">
                        <div class="contact-button-container">
                            <BaseButton class="blue-button" buttonText="Ajouter un contact" @click="addContact">
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                </template>
                            </BaseButton>
                        </div>
                        <div class="contact-container">
                            <div v-for="(contact, index) in supplier.contacts" :key="contact.id" class="card card-item">
                                <BaseButton class="white-button exit-btn" @click="removeContactFromFormError(index)">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', 'times']" class="exit-btn-icon" />
                                    </template>
                                </BaseButton>
                                <form>
                                    <div style="margin: 0 0 10px 15px;">
                                        <BaseRadioButton label="Contact principal" @onChange="onFormInputChange($event, 3)" v-model="supplier.contacts[index].main" :index="index" mode="boolean" value="true" name="contactMain"></BaseRadioButton>
                                    </div>
                                    <div>
                                        <BaseInput
                                            v-model="supplier.contacts[index].firstname"
                                            label="Prénom"
                                            type="text"
                                            :name="'contactFirstname_' + index"
                                            validator="text"
                                            :error="formErrors.step3['contactFirstname_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                        />
                                        <BaseInput
                                            v-model="supplier.contacts[index].lastname"
                                            label="Nom"
                                            type="text"
                                            :name="'contactLastname_' + index"
                                            validator="text"
                                            :error="formErrors.step3['contactLastname_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                        />
                                    </div>
                                    <div>
                                        <BaseInput
                                            v-model="supplier.contacts[index].email"
                                            label="Email"
                                            type="text"
                                            :name="'contactEmail_' + index"
                                            validator="email"
                                            :error="formErrors.step3['contactEmail_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                            @onButtonActionClick="onMailTo(supplier.contacts[index].email)"
                                        >
                                            <template v-slot:iconAction>
                                                <font-awesome-icon :icon="['fas', 'envelope']" />
                                            </template>
                                        </BaseInput>
                                    </div>
                                    <div>
                                        <BaseSelect
                                            v-model="supplier.contacts[index].job"
                                            label="Poste"
                                            :name="'contactJob_' + index"
                                            :required="false"
                                            :default-options="contactJobsOptions"
                                        ></BaseSelect>
                                    </div>
                                    <div>
                                        <BaseInput
                                            v-model="supplier.contacts[index].phoneNumber"
                                            label="Téléphone fixe"
                                            type="text"
                                            :name="'contactPhoneNumber_' + index"
                                            validator="phone"
                                            :error="formErrors.step3['contactPhoneNumber_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                            :required="false"
                                        />
                                        <BaseInput
                                            v-model="supplier.contacts[index].mobilePhoneNumber"
                                            label="Téléphone portable"
                                            type="text"
                                            :name="'contactMobilePhoneNumber_' + index"
                                            validator="phone"
                                            :error="formErrors.step3['contactMobilePhoneNumber_' + index]?.error?.message"
                                            @onChange="onFormInputChange($event, 3)"
                                            :displayError="displayError"
                                            :required="false"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div data-tab-name="note">
                        <div class="note-container">
                            <div class="card add-note-button-div">
                                <div class="add-note-title">Ajouter une note</div>
                                <BaseSelect
                                    v-model="newNote.contacts"
                                    :defaultOptions="contactOptions"
                                    name="contact"
                                    label="Contact(s)"
                                    fieldLabel="lastname"
                                    fieldValue="id"
                                    :multiple="true"
                                    :displayTag="true"
                                />
                                <BaseInput
                                    v-model="newNote.content"
                                    type="textarea"
                                    name="noteContent"
                                    placeholder="Note ..."
                                    :required="true"
                                    :maxLength="'1000'"
                                    :error="noteFormErrors?.noteContent?.error?.message"
                                    @onChange="onFormCreateNoteInputChange"
                                    :displayError="displayNoteError"
                                />
                                <BaseButton @click="addNote" buttonText="" title="Ajouter une note" class="add-note-button">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fal', 'plus']" />
                                    </template>
                                </BaseButton>
                            </div>
                            <div v-for='note of supplier.notes.filter(note => !note.deleted).sort((a,b) => { return compareDate(a.creationDate, b.creationDate) })' :key="note.id" class="note card-item">
                                <div class="note-item_header">
                                    <BaseButton @click="function() { deleteNote(note.id) }" buttonText="" title="Supprimer la note" class="delete-note-button">
                                        <template v-slot:iconBefore>
                                            <font-awesome-icon :icon="['fas', 'times']" />
                                        </template>
                                    </BaseButton>
                                </div>
                                <div class="note-item_time">{{ dateFormat(note.creationDate, 'HH:mm') }}<span v-if="!isSameDay(note.creationDate, new Date())"> - {{ new Date(note.creationDate).toLocaleDateString() }}</span></div>
                                <div class="note-item_contacts">
                                    <div v-for="contact of note.contacts">
                                        <div v-if="contact.deleted === undefined || contact.deleted === false" class="contact-tag">{{ contact.data.firstname.charAt(0) }}. {{ contact.data.lastname }} - {{ contact.data?.job?.value }}</div>
                                    </div>
                                </div>
                                <BaseInput
                                    v-model="note.content"
                                    label="Note"
                                    :name="'noteContent_' + note.id"
                                    type="textarea"
                                    style="height: 100%;"
                                    :model-value="note.content"
                                    :error="editNoteFormErrors?.['noteContent_' + note.id]?.error?.message"
                                    @onChange="onFormEditNoteInputChange"
                                    :displayError="displayEditNoteError"
                                />
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:modalMenuFooter>
                    <BaseButton class="orange-button" buttonText="Enregistrer" @click="onValidateForm">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'save']" />
                        </template>
                    </BaseButton>
                </template>
            </ModalMenu>
        </template>
    </Modal>

    <Dialog ref="dialog" />
</template>

<script>
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar'
    import BaseRadioButton from '../../components/Base/BaseRadioButton'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseCheckbox from '../../components/Base/BaseCheckbox'
    import BaseSelect from '../../components/Base/BaseSelect'
    import ListWithDelete from '../../components/App/ListWithDelete'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListSuppliersActionButtonsRenderer from './ListSuppliersActionButtonsRenderer'
    import Modal from '../../components/App/Modal'
    import ModalMenu from '../../components/App/ModalMenu'
    import Dialog from '../../components/App/Dialog'
    import axios from 'axios'
    import FieldSet from '../../components/App/FieldSet'
    import {compareDesc, format, isSameDay} from "date-fns";
    import {createToast} from "mosha-vue-toastify";
    import ContactJobEnum from "@/enums/contactJobEnum";

    export default {
        name: 'Suppliers',
        components: {
            ButtonTopbar,
            BaseRadioButton,
            BaseButton,
            BaseInput,
            BaseCheckbox,
            BaseSelect,
            ListWithDelete,
            Aggrid,
            Modal,
            ModalMenu,
            Dialog,
            FieldSet
        },
        data() {
            return {
                columnDefs: [
                    { field: 'name', headerName : `Nom`, flex: 1, sortable: true, lockPosition: true },
                    { field: 'accountNumber', headerName : `Numéro de compte`, flex: 1, sortable: true, lockPosition: true },
                    { field: 'phoneNumber', headerName : `Téléphone`, flex: 1, lockPosition: true },
                    {
                        headerName : `Contacts`,
                        flex: 1,
                        cellRendererFunctionName: 'ContactsRendererFunction',
                        suppressMenu: true},
                    { headerName : '', width: 270, cellRenderer: 'ListSuppliersActionButtonsRenderer', suppressMenu: true }
                ],
                frameworkComponents: {
                    ListSuppliersActionButtonsRenderer: ListSuppliersActionButtonsRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'ContactsRendererFunction',
                        function(param) {
                            let contacts = '';
                            param?.data?.contacts.forEach((contact) => {
                                contacts += `<div>${contact.email}</div>`
                            })
                            return contacts;
                        }
                    ]
                ]),
                context: null,
                displayModalCreateOrEditSupplier: false,
                createOrEditMode: null,
                supplier: null,
                form: {
                    contactCurrent: {
                        firstname: null,
                        lastname: null,
                        email: null,
                        job: {},
                        phoneNumber: null,
                        main: false
                    }
                },
                filter: {
                    deleted: false
                },
                displayError: false,
                formErrors: {
                    step1: {},
                    step2: {},
                    step3: {}
                },
                taxCode: [
                    { label: '0 TVA Export', value: 'tax_0' },
                    { label: '1 TVA 20%', value: 'tax_1' },
                    { label: '3 TVA Intra déductible', value: 'tax_3' },
                    { label: '4 TVA Exonérée France', value: 'tax_4' }
                ],
                taxSystem: [
                    { label: '00 Export', value: '21' },
                    { label: '10 France', value: '00' },
                    { label: '30 Intra', value: '20' },
                    { label: '40 Précise', value: '01' }
                ],
                currencie: [
                    { label: 'CHF', value: 'chf' },
                    { label: 'Dollars', value: 'usd' },
                    { label: 'Euro', value: 'euro' },
                    { label: 'Livres', value: 'livres' }
                ],
                language: [
                    { label: 'Français', value: 'french' },
                    { label: 'Anglais', value: 'english' },
                ],
                payment: [
                    { label: 'Chèque', value: 'check' },
                    { label: 'Virement', value: 'bankTransfer' },
                    { label: 'Caisse', value: 'fund'},
                    { label: 'Carte bancaire', value: 'bankCard'},
                    { label: 'Prélèvement', value: 'levy'},
                    { label: 'Lettre de Change Relevé', value: 'lcr'}
                ],
                deadline: [
                    { label: 'Comptant', value: 'cash' },
                    { label: 'Fin de mois + nb jours', value: 'endOfMonth1' },
                    { label: 'Nb jours + fin de mois', value: 'endOfMonth2' },
                    { label: 'Nb jours + fin de mois le 15', value: 'endOfMonth3' },
                    { label: 'Nb jours net', value: 'daysNumber' },
                    { label: 'Avant enlèvement', value: 'beforeRemoval' },
                ],
                menuItems: this.getMenuItems(),
                startSupplierModalTab: "general",
                displayDueDay: false,
                contactOptions: [],
                newNote: {
                    contacts: null,
                    content: null
                },
                noteFormErrors: {},
                displayNoteError: false,
                editNoteFormErrors: {},
                displayEditNoteError: false,
                contactJobsOptions: []
            }
        },
        watch: {
            'supplier.contacts': {
                handler(contacts) {
                    let count = 0;
                    this.contactOptions = [];
                    for (let contact of this.supplier.contacts) {
                        if (contact.firstname !== null && contact.lastname !== null && contact.email !== null) {
                            this.contactOptions.push({
                                id: contact.firstname + '_' + contact.lastname + '_' + count,
                                firstname: contact.firstname,
                                lastname: contact.lastname,
                                email: contact.email,
                                job: contact.job?.value
                            });
                            count++;
                        }
                    }
                    this.supplier?.notes?.forEach((note) => {
                        note.contacts?.forEach(current => {
                            if (this.supplier.contacts.find(contact => contact.firstname === current.data.firstname && contact.lastname === current.data.lastname && contact.email === current.data.email) === undefined) {
                                current.deleted = true;
                                if (note.contacts.filter(contact => contact.deleted === false).length === 0) {
                                    note.deleted = true;
                                }
                            }
                        });
                    })
                },
                deep: true
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetSupplier();
        },
        mounted() {
            if (this.$route.query?.action === 'addSupplier') {
                this.displayCreateSupplierModal();
            }

            this.contactJobsOptions = Object.values(ContactJobEnum).map(job => {
                return {
                    label: job,
                    value: job
                }
            })
        },
        methods: {
            closeModal() {
                this.resetSupplier();
                this.startSupplierModalTab = 'general';
                this.displayModalCreateOrEditSupplier = false;
                this.displayError = false;
                this.displayNoteError = false;
                this.newNote = {
                    contacts: null,
                    content: null
                }
            },
            onAccessWebsite(url) {
                if (url != null && url.trim().length > 0) window.open(url, '_blank').focus();
            },
            onMailTo(email) {
                if (email != null && email.trim().length > 0) window.location.href = `mailto:${email}`
            },
            displayCreateSupplierModal() {
                this.menuItems = this.getMenuItems();
                this.createOrEditMode = 'create';
                this.resetSupplier();

                // On spécifie les valeurs par défauts
                this.supplier.taxCode = {
                    value: 'tax_1',
                    label: '1 TVA 20%'
                };
                this.supplier.taxSystem = {
                    value: '10',
                    label: '10 France'
                };
                this.supplier.currencie = {
                    value: 'euro',
                    label: 'Euro'
                };
                this.supplier.language = {
                    value: 'french',
                    label: 'Français'
                };

                axios
                .get('supplier/getNewAccountNumber', { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((result) => {
                    this.supplier.accountNumber = result.data.toString();
                    this.displayModalCreateOrEditSupplier = true;
                })
                .catch(() => {});
            },
            displayEditSupplierModal(supplier) {
                this.menuItems = this.getMenuItems();
                this.createOrEditMode = 'edit';

                axios
                .get(`supplier/${supplier.id}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((result) => {
                    this.supplier = result.data;
                    this.supplier.taxCode = this.taxCode.find(taxCode => taxCode.value === supplier.taxCode);
                    this.supplier.taxSystem = this.taxSystem.find(taxSystem => taxSystem.value === supplier.taxSystem);
                    this.supplier.currencie = this.currencie.find(currencie => currencie.value === supplier.currencie);
                    this.supplier.language = this.language.find(language => language.value === supplier.language);
                    this.supplier.payment = this.payment.find(payment => payment.value === supplier.payment);
                    this.supplier.deadline = this.deadline.find(deadline => deadline.value === supplier.deadline);
                    this.supplier.contacts.map(contact => contact.job ? contact.job = { value: contact.job, label: contact.job } : {})
                    if (!this.supplier.dueDay) this.supplier.dueDay = 0;

                    this.supplier.deliveryZipCode = {label: this.supplier.deliveryZipCode, value: -1, data: {name: this.supplier.deliveryTown}}
                    this.supplier.deliveryTown = {label: this.supplier.deliveryTown, value: -1}
                    this.supplier.billingZipCode = {label: this.supplier.billingZipCode, data: {name: this.supplier.billingTown}}
                    this.supplier.billingTown = {label: this.supplier.billingTown}

                    this.displayModalCreateOrEditSupplier = true;
                })
                .catch(() => {});
            },
            displayEditContactsModal(supplier) {
                this.startSupplierModalTab = 'contact';
                this.displayEditSupplierModal(supplier);
            },
            resetSupplier () {
                this.supplier = {
                    name: null,
                    accountNumber: null,
                    callKey: null,
                    siret: null,
                    tvaNumber: null,
                    nafCode: null,
                    phoneNumber: null,
                    website: null,
                    billingAddress1: null,
                    billingAddress2: null,
                    billingAddress3: null,
                    billingZipCode: null,
                    billingTown: null,
                    billingCountry: null,
                    deliveryAddress1: null,
                    deliveryAddress2: null,
                    deliveryAddress3: null,
                    deliveryZipCode: null,
                    deliveryTown: null,
                    deliveryCountry: null,
                    contacts: [{
                        firstname: null,
                        lastname: null,
                        email: null,
                        job: {},
                        phoneNumber: null,
                        mobilePhoneNumber: null,
                        main: true
                    }],
                    comment: null,
                    currencie: null,
                    deadline: null,
                    dueDay: 0,
                    language: null,
                    payment: null,
                    taxCode: null,
                    taxSystem: null,
                    notes: []
                };
            },
            formateSupplier(supplier) {
                supplier = JSON.parse(JSON.stringify(supplier));

                if(supplier.website === '') {
                    supplier.website = null;
                }

                supplier.taxCode = supplier.taxCode.value;
                supplier.taxSystem = supplier.taxSystem.value;
                supplier.currencie = supplier.currencie.value;
                supplier.language = supplier.language.value;
                supplier.payment = supplier.payment.value;
                supplier.deadline = supplier.deadline.value;
                supplier.contacts.forEach(contact => {
                    contact.job = contact.job?.value
                })

                supplier.notes = supplier.notes.filter(note => !(note.isTemp && note.deleted));

                supplier.deliveryZipCode = supplier.deliveryZipCode?.label
                supplier.deliveryTown = supplier.deliveryTown?.label
                supplier.billingZipCode = supplier.billingZipCode?.label
                supplier.billingTown = supplier.billingTown?.label

                return supplier;
            },
            createSupplier() {
                if(!this.checkForm()) return;
                if(!this.checkEditNoteForm()) return;

                axios
                .post('supplier/create', this.formateSupplier(this.supplier), { 
                    toastSuccessMessage: `Fournisseur créé`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            editSupplier() {
                if(!this.checkForm()) return;
                if(!this.checkEditNoteForm()) return;

                axios
                .put('supplier/update', this.formateSupplier(this.supplier), { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModal();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            onFormInputChange(input, stepIndex) {
                let step = `step${stepIndex}`;

                if (input.error && input.error?.message !== null) {
                    this.formErrors[step][input.name] = input;
                } else {
                    delete this.formErrors[step][input.name];
                }

                switch (input.name) {
                    case 'name':
                        this.supplier.callKey = input?.value ? input.value.replace(/\s/g, '').toUpperCase().substr(0, 7) : null;
                    break;
                    case 'contactMain':{
                        (this.supplier.contacts).forEach((contact,index) => {
                            if(index !== input.index) {
                                contact.main = false;
                            }
                        })
                        break;
                    }
                    case 'deadline':
                        this.displayDueDay = input?.value?.value === 'endOfMonth1' || input?.value?.value === 'endOfMonth2' || input?.value?.value === 'endOfMonth3' || input?.value?.value === 'daysNumber';

                        if (!this.displayDueDay) delete this.formErrors['step2']['dueDay'];
                    break;
                }

                if (this.displayError) {
                    this.menuItems = this.getMenuItems();
                }
            },
            checkForm() {
                this.displayError = true;
                this.menuItems = this.getMenuItems();

                let formIsOk = true;
                for (let key of Object.keys(this.formErrors)) {
                    if (this.formErrors[key] != null && Object.keys(this.formErrors[key]).length > 0) {
                        formIsOk = false;
                        break;
                    }
                }

                return formIsOk;
            },
            onSearchInputChange(input) {
                if(this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }
                
                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                }, 250)   
            },
            onDeletedFilterChange(deleted) {
                this.filter.deleted = deleted;
            },
            deleteOrEnableSupplier(supplier) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir ${supplier.deleted ? 'ré-activer' : 'désactiver'} ce fournisseur ?`
                }).then(() => {
                    axios
                    .put('supplier/changeDeleted/' + supplier.id, {
                        deleted: !supplier.deleted
                    }, { 
                        toastSuccessMessage: `Modification effectuée`, 
                        toastError: true, 
                        showPreloader: true 
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload')
                    });
                })
                .catch(() => {});
            },
            onValidateForm(){
                if(this.createOrEditMode === 'create'){
                    this.createSupplier()
                }
                else{
                    this.editSupplier()
                }
            },
            addContact() {
                this.supplier.contacts.push({
                    firstname: null,
                    lastname: null,
                    email: null,
                    job: {},
                    phoneNumber: null,
                    mobilePhoneNumber: null,
                    main: false
                });
            },
            removeContactFromFormError(index) {
                for(let key of Object.keys(this.formErrors[`step3`])) {
                    if(parseInt(key.split('_')[1]) == index) {
                        delete this.formErrors['step3'][key];
                    }
                }
                this.supplier?.contacts?.splice(index, 1)
            },
            getMenuItems() {
                let menuItems = [
                    { name: "general", label: "Général", step: 1 }, 
                    { name: "payment", label: "Réglement", step: 2 },
                    { name: "contact", label: "Contact", step: 3 },
                    { name: "note", label: "Note", step: 4 }
                ];

                return menuItems.map(menuItem => {
                    let menuItemFormatted = {
                        name: menuItem.name,
                        label: menuItem.label
                    };

                    if (this.displayError) {
                        let step = `step${menuItem.step}`
                        if (this.formErrors[step] == null || Object.keys(this.formErrors[step]).length === 0) {
                            menuItemFormatted.checked = true;
                        } else {
                            menuItemFormatted.unchecked = true;
                        }
                    }

                    return menuItemFormatted;
                });
            },
            addNote() {
                if (!this.checkNoteForm()) return;

                this.newNote.contacts?.forEach(contact => contact.deleted = false);
                this.supplier.notes.push({
                    d: Math.floor(Math.random() * Date.now()),
                    isTemp: true,
                    deleted: false,
                    creationDate: new Date(),
                    content: this.newNote.content,
                    contacts: this.newNote.contacts
                });
                this.newNote = {
                    content: null,
                    contacts: null
                }
                this.displayNoteError = false;
                this.noteFormErrors = {};
            },
            deleteNote(id) {
                this.supplier.notes[this.supplier.notes.findIndex(note => note.id === id)].deleted = true;
            },
            onFormCreateNoteInputChange(input) {
                if (input.error?.message !== null) {
                    this.noteFormErrors[input.name] = input;
                } else {
                    delete this.noteFormErrors[input.name];
                }
            },
            onFormEditNoteInputChange(input) {
                if (input.error?.message !== null) {
                    this.editNoteFormErrors[input.name] = input;
                } else {
                    delete this.editNoteFormErrors[input.name];
                }
            },
            checkNoteForm() {
                this.displayNoteError = true;
                return Object.keys(this.noteFormErrors).length === 0;
            },
            checkEditNoteForm() {
                this.displayEditNoteError = true;
                return Object.keys(this.editNoteFormErrors).length === 0;
            },
            isSameDay(date1, date2) {
                return isSameDay(new Date(date1), new Date(date2));
            },
            dateFormat(date1, string) {
                return format(new Date(date1), string);
            },
            compareDate(date1, date2) {
                return compareDesc(new Date(date1), new Date(date2));
            },
            openBlockOrUnblockModel(supplierId, isBlocked, blockingComment) {
                if (!isBlocked) {
                    this.$refs.dialog.show({
                        type: 'prompt',
                        promptMode: 'input',
                        promptType: 'textarea',
                        title: 'Bloquer le fournisseur',
                        label: 'Commentaire / Raison',
                        message: `Merci de spécifier la raison du bloquage :`,
                        okButton: 'Bloquer'
                    }).then((input) => {
                        if (input == null || input.trim().length === 0) {
                            createToast(`Merci de saisir une raison ou un commentaire`, {
                                position: 'bottom-right',
                                type: 'danger',
                                hideProgressBar: true,
                                showIcon: true,
                                transition: 'slide'
                            });
                        } else {
                            axios
                                .put('supplier/changeBlocked/' + supplierId, {
                                    blocked: !isBlocked,
                                    blockingComment: input
                                }, {
                                    toastSuccessMessage: `Fournisseur bloqué`,
                                    toastError: true,
                                    showPreloader: true
                                })
                                .then(() => {
                                    this.emitter.emit('ag-grid-reload')
                                });
                        }
                    })
                        .catch(() => {});
                } else {
                    this.$refs.dialog.show({
                        type: 'confirm',
                        title: 'Débloquer le fournisseur',
                        message: 'Êtes-vous sûr de vouloir débloquer ce fournisseur ?' + (blockingComment !== '' ? `<br><br><div style="text-align: center">Raison du bloquage :</div><div style="text-align: center; color: grey">${blockingComment}</div>` : ''),
                        okButton: 'Débloquer'
                    }).then(() => {
                        axios
                            .put('supplier/changeBlocked/' + supplierId, {
                                blocked: !isBlocked
                            }, {
                                toastSuccessMessage: `Fournisseur débloqué`,
                                toastError: true,
                                showPreloader: true
                            })
                            .then(() => {
                                this.emitter.emit('ag-grid-reload')
                            });
                    })
                        .catch(() => {});
                }
            }
        }
    }
</script>