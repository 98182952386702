<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton @click="onEditContacts" buttonText="" title="Consulter les contacts">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'address-book']" />
            </template>
        </BaseButton>
        <BaseButton v-if="this.params.data.blocked === false" @click="onBlockOrUnblockCustomer" buttonText="" title="Bloquer le client">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'lock']" />
            </template>
        </BaseButton>
        <BaseButton v-if="this.params.data.blocked === true" @click="onBlockOrUnblockCustomer" buttonText="" title="Débloquer le client">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'unlock']" />
            </template>
        </BaseButton>
        <BaseButton v-if="this.params.data.deleted === false" @click="onDeleteOrEnableSupplier" buttonText="" title="Supprimer le fournisseur">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
        <BaseButton v-if="this.params.data.deleted === true" @click="onDeleteOrEnableSupplier" buttonText="" title="Restaurer le fournisseur">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash-restore']" />
            </template>
        </BaseButton>

    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListSuppliersActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onDeleteOrEnableSupplier() {
                this.params.context.componentParent.deleteOrEnableSupplier(this.params.data);
            },
            onEditSupplier() {
                this.params.context.componentParent.displayEditSupplierModal(this.params.data);
            },
            onEditContacts() {
                this.params.context.componentParent.displayEditContactsModal(this.params.data);
            },
            onHistory() {
            
            },
            onBlockOrUnblockCustomer() {
                this.params.context.componentParent.openBlockOrUnblockModel(this.params.data.id, this.params.data.blocked, this.params.data.blockingComment);
            }
        }
    }        
</script>